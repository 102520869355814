.App {
  display: grid;
  height: 100vh;
  width: 100%;
  max-width: 384px;
  margin: auto;
}

.HeaderWrapper {
  padding: 16px 16px 0 16px;
}

form > .HeaderWrapper {
  padding: 0;
}

.HeaderWrapper > div {
  margin: 0 !important;
}

.DropZoneContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.DropZone {
  width: 100%;
  border: 2px dashed var(--ds-border-input, #dfe1e6);
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 3px;
  gap: 8px;
  user-select: none;
}

.FileList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.FileListItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 8px;
}

.FileListItemName {
  color: #0073cf;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: left;
}

.FileListItemCheck {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #008000;
}

.FileListItemFail {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e34935;
}

.FileListItemClear {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
}
